import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import "./list.scss"
import List from '../components/pages/list/list'
import Transition from '../components/transition/transition'

let positions = []

for(let i = 0; i < 400; i++){ 
  positions.push(Math.floor(Math.random() * 5))
}

const ListPage = ({ data }) => {

  const list = useMemo(() => {

    const posts = [...data.wpgraphql.posts.nodes]
    .map(post => {

      const year = new Date(post.date).getFullYear()
      // console.log(post)

      return {
        ...post,
        date: year,
        // TODO: add Field in Backend!
        // details: 'Quick Info about Project here',
        title: post.title,
        type: 'Project'
      }
    })

    return [
      ...data.wpgraphql.pages.nodes[0].info.list,
      ...posts
    ]
    .sort((a, b) => parseInt(a.date) < parseInt(b.date) ? 1 : -1 )
  }, [data])

  const listTitle = data.wpgraphql.pages.nodes[0].info.listTitle


  useEffect(() => {
    // Make sure, page starts at the top!
    const main = document.querySelector('main')
    if(main) main.scrollTop = 0

  }, [])

  return (
    <Transition>
      <div>
        <List list={list} title={listTitle} header={['Date', 'Title', 'Details', 'Type']}/>
        {/* <List list={list} title={listTitle} header={['Date', 'Title', 'Details', 'Type']}/> */}
      </div>
    </Transition>
  )
}

export default ListPage

export const listQueryAndListQuery = graphql`
  query listQueryAndListQuery {
    wpgraphql{
      posts(first: 200) {
        nodes {
          title
          slug
          date
          categories{
            nodes{
              slug
            }
          }
          project {
            list {
              label
              text
            }
            preview {
              __typename
              ... on WPGraphQL_Post_Project_Preview_Image {
                image {
                  
                  title
                  sourceUrl
                  altText
                  mediaDetails {
                    width
                    height
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
              ... on WPGraphQL_Post_Project_Preview_Video {
                video {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                }
                image {
                  sourceUrl
                  mediaDetails {
                    width
                    height
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
      pages(where: {name: "info"}) {
        nodes {
          title
          id
          slug
          info {
            listTitle
            list {
              date
              title
              link
              details
              type
            }
          }
        }
      }
    }
  }
`
