import React from "react"
import './buttonSort.scss'

const SortButton = ({ onClickHandler, order, label }) => {
    const size = {
      h: 8,
      w: 16
    }
  
    const ArrowPosition = [
      `M1  ${size.h/2},   ${size.w/2}  ${size.h/2}, ${size.w-1} ${size.h/2}`,
      `M1 1,              ${size.w/2}  ${size.h-1}, ${size.w} 1`,
      `M1 ${size.h},      ${size.w/2}  1,           ${size.w} ${size.h}`,
    ]
  
    return(
      <div className="button__sort" onClick={onClickHandler} onKeyDown={onClickHandler} role="button" tabIndex={0} aria-label={`Sort List by ${label}`}>
        <div className="button__sort__label">{label}</div>
        <div className="button__sort__icon">
            <svg viewBox={`0 0 ${size.w} ${size.h}`} height={`${size.h}px`} width={`${size.w}px`}>
            <path strokeWidth="1" stroke="black" fill="transparent" d={ArrowPosition[order]}/>
            </svg>
        </div>
      </div>
    )
}

export default SortButton