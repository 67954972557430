import React, { useEffect, useState } from "react"
import ButtonSort from '../../buttons/buttonSort'
import { orderBy } from '../../../helpers/orderList'
import style from './list.module.scss'
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink"
import { navigate } from "gatsby"


const List = ({ list, title, header }) => {

    const [ state, setState ] = useState({
        orderedList: list,
        filteredList: list,
        orderTitle: 0,
        orderDate: 0,
        orderType: 0,
        orderDetails: 0,
        search_term: null
    })

    const sortByAlphabet = (sortBy) => {
        const newData = orderBy(state.orderedList, sortBy)
        const newDataFiltered = orderBy(state.filteredList, sortBy)

        setState({
            // ...state, 
            filteredList: newDataFiltered.items,
            orderedList: newData.items,
            orderTitle: sortBy === "title" ? newData.order : 0,
            orderDate: sortBy === "date" ? newData.order : 0,
            orderType: sortBy === "type" ? newData.order : 0,
            orderDetails: sortBy === "details" ? newData.order : 0,
        })

    }


    useEffect(() => {
        // Make sure, page starts at the top!
        const main = document.querySelector('main')
        if(main) main.scrollTop = 0
    
    })



    // useEffect()

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const search_term = params.get("search_term") || null

        if(search_term){
            console.log(search_term)

            const filteredList = [...state.orderedList].filter(item => {

                // console.log(item)
                const search_term_regExp = new RegExp(search_term, 'ig')

                const titleMatch =  item.title.match(search_term_regExp) ? true : false
                const detailsMatch = item.details && item.details.match(search_term_regExp) ? true : false
                const typeMatch =  item.type && item.type.match(search_term_regExp) ? true : false
                const dateMatch =  item.date && item.date.toString().match(search_term_regExp) ? true : false
                console.log(item)
                // const dateMatch =  false
                // const categoryMatch =  item.details.match(search_term_regExp) ? true : false
                return titleMatch || detailsMatch || typeMatch || dateMatch
                // return titleMatch || detailsMatch || typeMatch || false

            })

            setState({...state, filteredList })
        }else{
            setState({...state, filteredList: state.orderedList })

        }

    }, [state.search_term])

    const handleSearch = (value) => {

        if(!!value)navigate(`?search_term=${value}`)
        else navigate(`/list`)

        setState({...state, search_term: value})
    }

    return(
        <div className="page__list">
            {/* <h2>{title}</h2> */}
            <div className={style.info__list}>
            
            <div className={`${style.info__list__header} list__header`} >
                <ButtonSort onClickHandler={() => { sortByAlphabet("date") } } order={state.orderDate} label={header[0]}/>
                <ButtonSort onClickHandler={() => { sortByAlphabet("title") } } order={state.orderTitle} label={header[1]}/>
                <div>
                    <input
                    type="text"
                    placeholder="Search …" 
                    onKeyUp={(e) => handleSearch(e.currentTarget.value)} 
                    />
                </div>
                </div>
                {
                    state.filteredList && state.filteredList.map((listItem, i) => {

                        const content = (
                            <>
                                <div>
                                    {listItem.date}
                                </div>
                                <div>
                                    {listItem.title}
                                </div>
                                <div>
                                    {listItem.type}
                                </div>
                            </>
                        )

                        if(listItem.slug){
                            return (
                                <TransitionLink to={`/${listItem.slug}`} key={i}>
                                    <div className={style.info__list__el} >
                                        {content}
                                    </div>
                                </TransitionLink>
                            )
                        }else{
                            return <div key={i} className={style.info__list__el} >{content}</div>
                        }
                    })
                }
            </div>
        </div>
    )

}

export default List