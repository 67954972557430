const orderBy = (items, key) => {

    if(!items) return false
   
    let inverseOrder = 0
    let order = 1

    const newPostOrder = items.sort((a, b) => {

        // Store liste comparison
        const localeCompare = b[key].toString().localeCompare(a[key].toString())

        // console.log("localCompare: ", localeCompare)
        // console.log(b[key], a[key])
        // Count value to check if list already is in order
        inverseOrder += localeCompare

        return localeCompare
    })
    // console.log(inverseOrder, items.length-1)
    // If the absolute value qquals the length of item, reverse the order
    if(Math.abs(inverseOrder <= items.length-1) && inverseOrder > 0) newPostOrder.reverse()
    if(inverseOrder < 0) order = 2

    const orderObject = {
        items: newPostOrder,
        order
    }

    return orderObject;



}


const orderByYear = (posts) => {
    if(!posts) return false
    else{
        // console.log(posts)
        const newPostOrder = posts.sort((a, b) => {
            return(
                b.node.acf["year"].localeCompare(a.node.acf["year"])
            )
        })
        return newPostOrder;
    }
}

const orderByAlphabet = (posts) => {
    // if(!posts) return false
   
    // let inverseOrder = 1
    // const newPostOrder = posts.sort((a, b) => {

    //     const localeCompare = b["title"].localeCompare(a["title"])
    //     // Invert order if already sorted
    //     // if(!localeCompare) inverseOrder = true
    //     inverseOrder += localeCompare
    //     // console.log(localeCompare)
    //     // console.log(inverseOrder)

    //     return localeCompare
    // })
    // console.log(posts.length)
    // console.log(inverseOrder)
    // if(Math.abs(inverseOrder === posts.length)) newPostOrder.reverse()
    // return newPostOrder;
    return orderBy(posts, "title")
}


export { orderBy, orderByAlphabet, orderByYear }